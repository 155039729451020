import React, { useEffect, useState } from "react";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { appendImage, createImage, createMarkup } from "../../utils";
import axios from "axios";
import Layout from "../../components/Layout";
import FilmFestivalHeader from "../../components/FilmFestival/Header";
import { apiRoutes } from "../../constants/apiRoutes";
import HeroSection from "../../components/FilmFestival/HeroSection";
import { Link } from "gatsby";
import routes from "../../components/constants/routes";
import TuncHtml from "trunc-html";
import Helmet from "../../components/Helmet";
import { ImageConstants } from "../../components/constants/Imageconstants";

const About = () => {
  const [apiData, setApiData] = useState({});
  const [filteredData, setFilteredData] = useState([]);

  const fetchAboutFestival = async () => {
    try {
      const { data } = await axios.get(apiRoutes.aboutFilmFestival);
      if (data) {
        setApiData(data.data.attributes);
      }
    } catch (error) {}
  };

  const truncate = (html) => {
    html = TuncHtml(html, 400).html;
    return html;
  };

  const fetchFilmNews = async () => {
    try {
      const { data } = await axios.get(
        apiRoutes.news(true, 5, "film festival")
      );
      const newsData = data?.data;
      if (newsData.length) setFilteredData(newsData);
    } catch (error) {}
  };

  useEffect(() => {
    fetchAboutFestival();
    fetchFilmNews();
  }, []);

  const sponserImage = apiData?.sponsor_image?.data?.attributes?.url;
  const youtubeUrl = apiData?.youtube_url;
  const aboutHistory = apiData?.about_history;
  const about_carousel = apiData?.about_carousel;
  const aboutScreeningEvent = apiData?.about_screening_event?.description;

  useEffect(() => {
    appendImage("rich-editor");
  }, [apiData]);

  const seoData = {
    title: "About Film Festivals Luxembourg | Celebrating Cinematic Excellence",
    description:
      "Explore the world of cinema with Film Festivals Luxembourg. Discover exceptional films, panel discussions, and exclusive screenings. Join us in celebrating the art of filmmaking.",
    url: `${process.env.GATSBY_SITE_URL}${routes.FFAbout}/`,
  };

  return (
    <Layout>
      <Helmet
        title={seoData.title}
        description={seoData.description}
        url={seoData?.url}
      />

      <div className="film-festival">
        <div className="container-fluid padding-0">
          <div className="row">
            <div className="col-12 pr-mob-0">
              <HeroSection />
              <div className="head-tab mt-40">
                <FilmFestivalHeader />
                {Object.values(apiData)?.length ? (
                  <div className="tab-content" id="nav-tabContent">
                    <div className="film-about">
                      <section className="ff-slider mt-80">
                        <div className="container-fluid">
                          <div className="row">
                            <div className="col-md-12 ff-slider-container">
                              <Swiper
                                modules={[Navigation, Autoplay]}
                                loop={true}
                                spaceBetween={10}
                                grabCursor={true}
                                centeredSlides={true}
                                slidesPerView={3}
                                navigation
                                pagination={{ clickable: true }}
                                autoplay={{
                                  delay: 5000,
                                  disableOnInteraction: false,
                                }}
                              >
                                {about_carousel?.carousel_image?.data.map(
                                  (el) => (
                                    <SwiperSlide>
                                      <div className="ff-slider-card swiper-image">
                                        <div className="image">
                                          <img
                                            src={createImage(el.attributes.url)}
                                            alt={
                                              about_carousel?.carousel_image_alt_text
                                            }
                                            width={"auto"}
                                            height={"auto"}
                                          />
                                        </div>
                                      </div>
                                    </SwiperSlide>
                                  )
                                )}
                              </Swiper>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <div
                                className="about-biffl rich-editor"
                                dangerouslySetInnerHTML={createMarkup(
                                  about_carousel?.description
                                )}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </section>

                      {filteredData?.length ? (
                        <section className="latest-news mt-100">
                          <h1 className="title text-center">Latest News</h1>
                          <div className="mt-50">
                            <div className="latest-news-container container">
                              <div className="row">
                                <div className="col-md-12">
                                  <Swiper
                                    modules={[
                                      Navigation,
                                      Pagination,
                                      Scrollbar,
                                      A11y,
                                      Autoplay,
                                    ]}
                                    spaceBetween={3}
                                    // slidesPerView={2}
                                    navigation
                                    pagination={{ clickable: true }}
                                    autoplay={{
                                      delay: 5000,
                                      disableOnInteraction: false,
                                    }}
                                    breakpoints={{
                                      320: {
                                        slidesPerView: 1,
                                        spaceBetween: 20,
                                      },
                                      768: {
                                        slidesPerView: 1.5,
                                        spaceBetween: 20,
                                      },
                                      1000: {
                                        slidesPerView: 1,
                                      },
                                      1300: {
                                        slidesPerView: 1,
                                      },
                                    }}
                                  >
                                    {filteredData?.map((el, index) => {
                                      const news = el.attributes;
                                      return (
                                        <SwiperSlide>
                                          <div className="latest-news-card row">
                                            <div className="col-md-12 col-lg-6">
                                              <Link
                                                to={`${routes.FFNews}?id=${el.id}`}
                                              >
                                                <img
                                                  src={createImage(
                                                    news?.image?.data
                                                      ?.attributes?.url
                                                  )}
                                                  alt={news?.image_alt_text}
                                                  width={"auto"}
                                                  height={"auto"}
                                                />
                                              </Link>
                                            </div>
                                            <div className="col-md-12 col-lg-6">
                                              <div className="content bg-purple">
                                                <Link
                                                  to={`${routes.FFNews}?id=${el.id}`}
                                                >
                                                  <h2>{news?.title}</h2>
                                                </Link>
                                                <div
                                                  className="rich-editor"
                                                  dangerouslySetInnerHTML={createMarkup(
                                                    truncate(news?.description)
                                                  )}
                                                ></div>
                                                <h3 className="readmore">
                                                  <Link
                                                    to={`${routes.FFNews}?id=${el.id}`}
                                                  >
                                                    {"Read more >>"}
                                                  </Link>
                                                </h3>
                                              </div>
                                            </div>
                                          </div>
                                        </SwiperSlide>
                                      );
                                    })}
                                  </Swiper>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      ) : null}

                      {aboutHistory ? (
                        <section className="history mt-100">
                          <h1 className="title text-center">History</h1>
                          <div className="container-fluid mt-30">
                            <div className="row">
                              <div className="col-12">
                                <div
                                  className="history-container rich-editor"
                                  dangerouslySetInnerHTML={createMarkup(
                                    aboutHistory
                                  )}
                                ></div>
                              </div>
                            </div>
                          </div>
                        </section>
                      ) : null}

                      {aboutScreeningEvent ? (
                        <section className="upcoming-screening mt-50">
                          <h1 className="title text-center">
                            {apiData?.about_screening_event?.title}
                          </h1>
                          <div className="container mt-30">
                            <div className="row">
                              <div className="col-12">
                                <div className="upcoming-screening-body row">
                                  <div className="col-md-12 col-lg-6 screening-img">
                                    <img
                                      src={ImageConstants.ffevent}
                                      alt="upcoming screening"
                                      width={"auto"}
                                      height={"auto"}
                                    />
                                  </div>
                                  <div className="col-md-12 col-lg-6">
                                    <div
                                      className="important-dates rich-editor"
                                      dangerouslySetInnerHTML={createMarkup(
                                        aboutScreeningEvent
                                      )}
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      ) : null}

                      {youtubeUrl ? (
                        <div className="upcoming-screening">
                          <div className="container mt-30">
                            <div className="row">
                              <div className="col-12 border-bottom">
                                <div className="screening-video">
                                  <iframe
                                    width="820"
                                    height="458"
                                    src={youtubeUrl}
                                    title="YouTube video player"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowfullscreen
                                  ></iframe>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}

                      {sponserImage ? (
                        <section className="sponsors mt-50">
                          <h1 className="title text-center">Sponsors</h1>
                          <div className="container-fluid mt-30">
                            <div className="row">
                              <div className="col-12">
                                <div className="sponsors-container">
                                  <img
                                    src={createImage(sponserImage)}
                                    alt={apiData?.sponsor_image_alt_text}
                                    width={"auto"}
                                    height={"auto"}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      ) : null}
                    </div>
                  </div>
                ) : (
                  <p className="text-center" style={{ margin: "15em 0" }}></p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default About;
